import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

import HeroCarousel from "../components/views/hero-carousel"
/*import Events from '../components/views/events'*/

import Image from "../components/image"
import { ArrowRight } from "react-bootstrap-icons"

export default function Home({ data }) {
  return (
    <>
      <style>{`
      
    `}</style>
      <Layout>
        <SEO title="Home" />
        <HeroCarousel />

        {/* <h3
          className="text-center"
          style={{
            marginTop: "150px",
            fontFamily: "'Kaushan Script', cursive",
            fontSize: "40px",
          }}
        >
          Checking In Checking Out
          <br />
          Making It Happen
          <br />
          Without A Doubt
        </h3>
        <br />
        <div className="d-flex justify-content-center">
          <Image
            src="3milwil-special-dark.png"
            style={{
              width: "800px",
            }}
            alt={data.site.siteMetadata.title}
          />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Image
            src="belt.png"
            alt={data.site.siteMetadata.title}
            style={{
              width: "300px",
              marginBottom: "50px",
            }}
          />
        </div>
        <hr /> */}

        <div className="section pb-0">
          <div className="container">
            <div className="row">
              <div className="text-center">
                <div className="d-flex justify-content-center">
                  <Image
                    src="xavier.jpg"
                    alt={data.site.siteMetadata.title}
                    style={{ width: "300px" }}
                    className="mb-4"
                  />
                </div>
                <h2>
                  My name is Xavier Williams and I am both a business owner and
                  entrepreneur here in San Francisco.
                </h2>
                <p>
                  I am native to the Bay Area, having grown up in Oakland before
                  moving to San Francisco in 1990 where I started a family and
                  built my business, 3 Mil Wil Hair Salon.
                </p>
                <p>
                  <Link to="/our-story" className="btn btn-lg">
                    Read more <ArrowRight />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*<Events />*/}
      </Layout>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        address
        phone
      }
    }
  }
`
