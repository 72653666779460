import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Carousel, Container, Row, Col } from "react-bootstrap"

import Image from "../image"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            phone
          }
        }
      }
    `
  )
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <>
      <style type="text/css">
        {`
        .dark-bg-hero-carousel {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.3);
            z-index: 2;
        }
        .carousel {
            max-height: 100vh;
            overflow: hidden;
        }
        .carousel-wrapper {
            position: relative;
            max-height: 100vh;
            overflow: hidden;
        }
        .hero-text {
            z-index: 3;
            top: 20%;
            bottom: unset;
            right: 0;
            left: 0;
            padding-top: 0;
            padding-bottom: 0;
            color: #fff;
            position: absolute;
        }
        .carousel-title {
            font-size: 40px;
            font-weight: 300;
            text-shadow: 0px 1px #000;
            font-family: 'Kaushan Script', cursive;
        }
        .carousel-indicators li {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            margin-right: 6px;
            margin-left: 6px;
        }
        .carousel-control-prev, .carousel-control-next {
            z-index: 3;
            opacity: 0.9;
        }
        .carousel-fade1 .carousel-item {
            opacity: 0;
            transition-duration: .6s;
            transition-property: opacity;
        }
        
        .carousel-fade1  .carousel-item.active,
        .carousel-fade1  .carousel-item-next.carousel-item-left,
        .carousel-fade1  .carousel-item-prev.carousel-item-right {
            opacity: 1;
        }
        
        .carousel-fade1 .active.carousel-item-left,
        .carousel-fade1  .active.carousel-item-right {
            opacity: 0;
        }
        
        .carousel-fade1  .carousel-item-next,
        .carousel-fade1 .carousel-item-prev,
        .carousel-fade1 .carousel-item.active,
        .carousel-fade1 .active.carousel-item-left,
        .carousel-fade1  .active.carousel-item-prev {
            transform: translateX(0);
            transform: translate3d(0, 0, 0);
        }

        .special-image {
          width: 100%;
          max-width: 500px;
          margin: 20px 0;
        }
        .special-image-2 {
          width: 250px;
          margin: 20px 0;
        }
         .wide {
              width: 200px;
            }

        @media (min-width: 940px) and (max-width: 1200px) {
          .hero-text {
                top: 16%;
            }
          .carousel-title {
            font-size: 30px;
          }
          .special-image {
            width: 400px;
            margin: 10px 0;
          }
          .special-image-2 {
            width: 250px;
            margin: 10px 0;
          }
           .wide {
              width: 150px;
            }
        }

        @media (min-width: 768px) and (max-width: 939px) {
          .hero-text {
                top: 14%;
          }
          .carousel-title {
            font-size: 25px;
          }
          .special-image {
            width: 300px;
            margin: 20px 0;
          }
          .special-image-2 {
            width: 200px;
            margin: 10px 0;
          }
           .wide {
              width: 150px;
            }
        }
        @media(max-width: 767px) {
            .hero-text {
                top: 13%;
            }
            .carousel-title {
                font-size: 25px;
            }
            .w-100 {
                width: 200% !important;
                margin-left: -40%;
            }
            .wide {
              width: 100px;
            }
        }
        
    `}
      </style>
      <div className="carousel-wrapper">
        <div className="dark-bg-hero-carousel"></div>
        <div className="hero-text">
          <Container>
            <Row>
              <Col className="text-center">
                <h3 className="carousel-title">
                  Checking In Checking Out
                  <br />
                  Making It Happen
                  <br />
                  Without A Doubt
                </h3>
                <div className="d-flex justify-content-center">
                  <Image
                    src="wide.png"
                    className="wide"
                    style={{
                      marginTop: "20px",
                    }}
                    alt={data.site.siteMetadata.title}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Image
                    src="3milwil-special.png"
                    alt={data.site.siteMetadata.title}
                    className="special-image"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <Image
                    src="belt.png"
                    alt={data.site.siteMetadata.title}
                    className="special-image-2"
                  />
                </div>
                <a
                  className="btn btn-primary mt-3"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  Appointments Call {data.site.siteMetadata.phone}
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Carousel
          interval={10000}
          controls={true}
          activeIndex={index}
          onSelect={handleSelect}
          className="carousel-fade1"
        >
          <Carousel.Item>
            <Image
              src="slider2.jpg"
              alt={data.site.siteMetadata.title}
              className="d-block w-100"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              src="slider1.jpg"
              alt={data.site.siteMetadata.title}
              className="d-block w-100"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  )
}
